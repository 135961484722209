import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Toast } from 'vant'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/loginUpg',
    name: 'loginUpg',
    component: () => import('../views/LoginUpg.vue')
  },
  {
    path: '/correlation',
    name: 'correlation',
    component: () => import('../views/Correlation.vue'),
    // children: [
    //   {
    //     path: 'relatedIssues/:2',
    //     name: 'relatedIssues',
    //     component: () => import('../views/RelatedIssues.vue')
    //   }
    // ]
  },
  {
    path: '/relatedIssues/:id',
    name: 'relatedIssues',
    component: () => import('../views/RelatedIssues.vue')
  },
  {
    path: '/qa/:id',
    name: 'qa',
    component: () => import('../views/Qa.vue')
  },
]

const router = new VueRouter({
  mode: "history",
  routes
})

//全局前置导航守卫

// 定义一个数组，放置不需要权限的页面
const authUrls = ['/', '/login', '/loginUpg']
router.beforeEach((to, from, next) => {
  //查看 to.path 是否在 authUrls 中出现
  if (authUrls.includes(to.path)) {
    next() //非权限页面直接放行
    return
  }
  //权限页面 ；判断token
  const token = store.getters.token
  if (token) {
    next()
  } else {
    Toast.fail('请先登录！')
    next('/login')
  }
})
export default router
