<template>
    <div class="quitBody">
        <van-button @click="quitLogin">退出登录</van-button>
    </div>

</template>

<script>
export default {
    name: "QuitLogin",
    data() {
        return {

        }
    },
    methods: {
        //退出登录
        quitLogin() {
            localStorage.clear()
            window.sessionStorage.clear()
            this.$router.push('/')
        }
    }
}

</script>

<style lang="less" scoped>
.van-button {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    border: 0.02667rem solid #ebedf0;
    border-top: none;
    border-left: none;
    border-right: none;
    font-size: 0.37333rem;
    color: #969799;
}
</style>